import React from "react";
import css from "./style.module.scss";
import { Title, SectionTitle, SubTitle, Mark } from "components/ui/Typography";
import Background from "components/ui/Background";
import Button from "components/ui/Button";
import cn from "classnames";
import { Container, Content, Section, Divider } from "components/ui/Components";

const Extension = ({ browser, image, soon, link }) => (
  <div className={cn(css.extension, soon && css.soon)}>
    <img src={"/assets/" + image} className={css.image} />

    <div className={css.name}>{browser}</div>

    <Button disabled={soon} link to={link} arrow={!soon} className={css.link}>
      {soon ? "Coming soon" : "Install it now"}
    </Button>
  </div>
);

export const HomepageDownload = ({ children }) => {
  return (
    <div className={cn(css.extensions, css.homepage)}>
      <Extension
        image="chrome.png"
        browser="Google Chrome"
        link="https://chrome.google.com/webstore/detail/tinysteps/lpklidbagfdnmhebdmhkonfegmnhombg?hl=en&authuser=0"
      />
      <Extension
        image="firefox.png"
        browser="Mozilla Firefox"
        link="https://addons.mozilla.org/en-US/firefox/addon/tinysteps/"
      />
      <Extension
        image="edge.png"
        browser="Microsoft Edge"
        link="https://microsoftedge.microsoft.com/addons/detail/tinysteps/jeddobndkoihnfnmlahboldippaojikg"
      />
    </div>
  );
};
export default ({ children }) => {
  return (
    <div className={css.hero} id="pricing">
      <Content>
        <div>
          <Title>Install it now</Title>
          <SubTitle>And if you have some time, leave a review 💜</SubTitle>

          <div className={css.extensions}>
            <Extension
              image="chrome.png"
              browser="Google Chrome"
              link="https://chrome.google.com/webstore/detail/tinysteps/lpklidbagfdnmhebdmhkonfegmnhombg?hl=en&authuser=0"
            />
            <Extension
              image="firefox.png"
              browser="Mozilla Firefox"
              link="https://addons.mozilla.org/en-US/firefox/addon/tinysteps/"
            />
            <Extension
              image="edge.png"
              browser="Microsoft Edge"
              link="https://microsoftedge.microsoft.com/addons/detail/tinysteps/jeddobndkoihnfnmlahboldippaojikg"
            />
          </div>
        </div>
      </Content>
      <Divider color="color" />
    </div>
  );
};
