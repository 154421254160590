import React from "react";
import css from "./style.module.scss";
import { Title, SectionTitle, SubTitle, Mark } from "components/ui/Typography";
import Background from "components/ui/Background";
import Button from "components/ui/Button";
import cn from "classnames";
import { Container, Content, Section, Divider } from "components/ui/Components";

const Plan = ({ name, value, sub }) => (
  <div className={css.plan}>
    <div className={css.title}>{name}</div>
    <div className={css.price}>
      <div className={css.value}>{value}</div>
      <div className={css.sub}>{sub}</div>
    </div>
  </div>
);

export default ({ children }) => {
  return (
    <div className={css.hero} id="pricing">
      <Content>
        <div>
          <Title>Pricing</Title>
          <SubTitle>We have a straightforward pricing offer for you.</SubTitle>

          <div className={css.pricings}>
            <Plan name="Monthly Subscription" value="4$" sub="/month" />
            <Plan name="Yearly Subscription" value="22$" sub="/year" />
          </div>
          <SectionTitle>
            <Mark>Why TinySteps isn't free?</Mark>
            <br />
            Charging for a subscription is the only way <a href="/about">
              we
            </a>{" "}
            make money. That way, we guarantee an ad-free access and the promise
            to never sell your data to third-parties!
          </SectionTitle>
        </div>
      </Content>
      <Divider color="color" />
    </div>
  );
};
