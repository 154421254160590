import React from "react";
import css from "./style.module.scss";
import { Title, SectionTitle, Text, Mark } from "components/ui/Typography";
import Background from "components/ui/Background";
import Button from "components/ui/Button";
import cn from "classnames";
import Animator from "components/ui/Animator";
import { Container, Content, Section, Divider } from "components/ui/Components";

export default ({ children }) => {
  return (
    <Container className={css.hero}>
      <Content>
        <div>
          <Title>
            50% Journal,
            <br /> 50% Habit tracker.
            <br /> 100% <Mark>Right for you</Mark>.
          </Title>
          <SectionTitle>
            TinySteps’ tailor-made formula consists of two parts:
            <ul>
              <li>
                A <b>journal</b>, where you can jot down your emotions or write
                anything you like,
              </li>
              <li>
                An <b>habit tracker</b>, where you can track your progress, form
                new habits, or check all the little things you’d like to improve
                in your life.
              </li>
            </ul>
          </SectionTitle>
        </div>
      </Content>
      <Divider color="color" />
    </Container>
  );
};
