import React from "react";
import css from "./style.module.scss";
import { Title, SectionTitle, Text, Mark } from "components/ui/Typography";
import Background from "components/ui/Background";
import Button from "components/ui/Button";
import cn from "classnames";
import Animator from "components/ui/Animator";
import { Container, Content, Section, Divider } from "components/ui/Components";

export default ({ children }) => {
  return (
    <Container className={css.hero}>
      <Content>
        <div>
          <Title>
            Yes, we have a <Mark>Privacy Policy</Mark>.
          </Title>
          <SectionTitle>
            This is the privacy policy for TinySteps (https://tinysteps.xyz) and
            all the other subdomains and websites that we own and operate. We
            want to keep it simple and we don't want to hide behind long
            paragraphs of text, small lines or difficult words.
            <br />
            <br />
            <Mark>What we collect</Mark>
            <br />
            Here is a list of things that we save of our users. We will update
            this list as soon as we will need some other personal data. (Last
            update: 24 June 2020).
            <br />
            <br />
            <b>Email address</b>: this is the only way to identify our users. We
            will use the email address to send daily or weekly recaps of your
            websites and also to send updates about the user's subscription or
            some big updates about TinySteps (planned downtimes, changes of this
            privacy policy...). We won't send you any newsletter. <br />
            <br />
            <b>Name</b>: we need your name to personalize your experience on
            TinySteps. You can also put a fake name or only your first name if
            you prefer.
            <br />
            <br />
            <b>Timezone</b>: we need this to group in the best way the data in
            our charts and also to understand what is the best moment to send
            our daily/weekly recaps.
            <br />
            <br />
            <b>The date of the last visit on our dashboard</b>: this is useful
            to understand if our paying users are using the service or they
            forgot about it, and in case ask if they want to cancel the
            subscription.
            <br />
            <br />
            <br />
            <br />
            <Mark>All payments data are handled securely by Stripe</Mark> and we
            don't have any information about your credit/prepaid cards on our
            server.
            <br />
            <br />
            <br />
            <br />
            If you have any questions about how we handle user data and personal
            information, feel free to{" "}
            <Button link external to="mailto:hey@tinysteps.xyz">
              contact us
            </Button>
            .
            <br />
            <br />
            If you continue to use our website we will assume that you accept
            our privacy policy and our practices for the handling of your
            personal information. This policy is effective as of 20 June 2020.
          </SectionTitle>
        </div>
      </Content>
      <Divider color="color" />
    </Container>
  );
};
