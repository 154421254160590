import React from "react";
import css from "./style.module.scss";
import { Title, SectionTitle } from "components/ui/Typography";
import Background from "components/ui/Background";
import Button from "components/ui/Button";
import cn from "classnames";
import Animator from "components/ui/Animator";
import { Container, Content, Section, Divider } from "components/ui/Components";

const Image = ({ src, className }) => {
  return (
    <div className={cn(css.image, className)}>
      <Animator delay="400">
        <img loading="lazy" src={"assets" + src}></img>
      </Animator>
    </div>
  );
};

export default ({ children, image }) => {
  return (
    <Container className={css.hero} noMargin>
      <Content>
        <Image src={"/" + image}></Image>
      </Content>
      <Divider color="white" />
    </Container>
  );
};
