import React from "react";
import css from "./style.module.scss";
import { Title, SectionTitle } from "components/ui/Typography";
import Background from "components/ui/Background";
import Button from "components/ui/Button";
import cn from "classnames";

export const Content = ({ children, center, responsive, className }) => {
  return (
    <div
      className={cn(
        css.content,
        center && css.center,
        responsive && css.responsive,
        className
      )}
    >
      {children}
    </div>
  );
};
export const Section = ({ children, center, className }) => {
  return (
    <div className={cn(css.section, center && css.sectionCenter, className)}>
      {children}
    </div>
  );
};

export const Container = ({
  children,
  color = "white",
  className,
  noMargin,
}) => {
  return (
    <div
      className={cn(
        css.container,
        css[color],
        noMargin && css.noMargin,
        className
      )}
    >
      {children}
    </div>
  );
};

export const Image = ({ src, className }) => {
  return (
    <div className={cn(css.image, className)}>
      <img src={"assets" + src}></img>
    </div>
  );
};

export const Divider = ({ children, color = "white", className }) => {
  return (
    <div className={cn(css.divider, css[color + "Divider"], className)}>
      <svg
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1200 120"
        preserveAspectRatio="none"
      >
        <path
          d="M1200 120L0 16.48 0 0 1200 0 1200 120z"
          class="shape-fill"
        ></path>
      </svg>
    </div>
  );
};
