import { render } from "preact";
import App from "./App";

// render into some specific element:

const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  render(<App />, rootElement, rootElement.firstElementChild);
} else {
  render(<App />, rootElement);
}

//export default App;
