import React from "react";
import css from "./style.module.scss";
import { Title, SectionTitle } from "components/ui/Typography";
import Background from "components/ui/Background";
import Button from "components/ui/Button";
import cn from "classnames";
import { Container, Content, Divider, Image } from "components/ui/Components";

export default ({ children }) => {
  return (
    <Container className={css.header} noMargin>
      <Content className={css.content} responsive>
        <div className={css.right}>
          <Button
            small
            link
            className={cn(css.link, css.login)}
            to="https://app.tinysteps.xyz/"
          >
            Login
          </Button>
        </div>
      </Content>
    </Container>
  );
};
