import React from "react";
import Header from "components/ui/Header";
import Footer from "components/ui/Footer";
import css from "./style.module.scss";

export const Page = ({ children }) => (
  <div className={css.page}>
    <div className={css.content}>
      {/* <Header /> */}
      {children}
      {/* <Footer /> */}
    </div>
  </div>
);

export default Page;
