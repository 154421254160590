import React from "react";
import { Link } from "preact-router";
import cn from "classnames";
import { trackEvent } from "shared/track";
import css from "./style.module.scss";

const Button = ({
  to,
  children,
  onClick,
  className,
  type = "button",
  link = false,
  secondary,
  big,
  small,
  external,
  nomargin,
  loading,
  disabled,
  alt,
  responsive,
  track,
  params = {},
  arrow = false,
}) => {
  const classNames = cn(
    link ? css.link : css.button,
    arrow && css.linkWithArrow,
    secondary && css.secondary,
    big && css.big,
    small && css.small,
    nomargin && css.nomargin,
    disabled && css.disabled,
    responsive && css.responsive,
    className
  );

  if (to) {
    if (external || to.startsWith("http")) {
      return (
        <a
          href={to}
          alt={alt}
          target="_blank"
          rel="noopener noreferrer"
          className={classNames}
          onClick={() => trackEvent(track)}
        >
          {children}
        </a>
      );
    }
    return (
      <Link
        className={classNames}
        alt={alt}
        href={to}
        onClick={() => trackEvent(track)}
      >
        {children}
      </Link>
    );
  }
  return (
    <button
      type={type}
      className={classNames}
      disabled={disabled}
      alt={alt}
      onClick={(e) => {
        // e.preventDefault();
        if (!loading && !disabled) {
          trackEvent(track);
          return onClick && onClick();
        }
      }}
    >
      {loading ? "Loading..." : children}
    </button>
  );
};

export default Button;
