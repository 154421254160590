import React from "react";
import css from "./style.module.scss";
import { Title, SectionTitle, Text, Mark } from "components/ui/Typography";
import Background from "components/ui/Background";
import Button from "components/ui/Button";
import cn from "classnames";
import Animator from "components/ui/Animator";
import { Container, Content, Section, Divider } from "components/ui/Components";

export default ({ children }) => {
  return (
    <Container className={css.hero}>
      <Content>
        <div>
          <Title>
            We are <Mark>Francesco</Mark> and <Mark> Virginia</Mark>,<br />
            and we love to build new things.
          </Title>
          <SectionTitle>
            Behind Tinysteps there isn't a big, heartless company, nor a fancy
            startup backed at the third round of a funding:{" "}
            <Mark>it's only the 2 of us</Mark>.<br />
            <br />
            We're from Italy, and we want to help as many people as possible
            with our products and ideas.
            <br />
            After launching a{" "}
            <Button link external to="https://metrical.xyz">
              privacy-first web analytics platform
            </Button>{" "}
            and a{" "}
            <Button link external to="https://feminipsum.space">
              feminist lorem ipsum generator
            </Button>{" "}
            we are back with <Mark>TinySteps</Mark>, to help you achieve your
            goals and track whatever you want to, everyday.
          </SectionTitle>
        </div>
      </Content>
      <Divider color="color" />
    </Container>
  );
};
