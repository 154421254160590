import React from "react";
import css from "./style.module.scss";
import { Title, SectionTitle } from "components/ui/Typography";
import Background from "components/ui/Background";
import Button from "components/ui/Button";
import cn from "classnames";
import Header from "components/ui/Header";
import Footer from "components/ui/Footer";
import Download from "./Download";
import HeroText from "./HeroText";

export default ({ children }) => {
  return (
    <div>
      <div className={css.homepage}>
        <Header />
        <HeroText />
        <Download />
        <Footer />
      </div>
    </div>
  );
};
