import React from "react";
import css from "./style.module.scss";
import { Title, SectionTitle, Text, Mark } from "components/ui/Typography";
import Background from "components/ui/Background";
import Button from "components/ui/Button";
import cn from "classnames";
import Animator from "components/ui/Animator";
import { Container, Content, Section, Divider } from "components/ui/Components";

export default ({ children }) => {
  return (
    <Container className={css.hero}>
      <Content>
        <div>
          <Title>Never forget about your habits.</Title>
          <SectionTitle>
            We designed some useful <Mark>Web Extensions</Mark> that
            automatically display <b>TinySteps</b> whenever you open a new tab
            on your Browser. <br />
            All steps toward your goals are just one click away.
            <Mark>Pinky promise!</Mark>
          </SectionTitle>
        </div>
      </Content>
      <Divider color="color" />
    </Container>
  );
};
