import React from "react";
import css from "./style.module.scss";
import { Title, SectionTitle } from "components/ui/Typography";
import Background from "components/ui/Background";
import Button from "components/ui/Button";
import cn from "classnames";
import Header from "components/ui/Header";
import Footer from "components/ui/Footer";
import UsPanel from "./UsPanel";
import HeroText from "./HeroText";
// import HeroImage from "./HeroImage";

export default ({ children }) => {
  return (
    <div>
      <div className={css.homepage}>
        {/* <HeroHeader /> */}
        <Header />
        <HeroText />
        <UsPanel />
        {/* <HeroImage image="homepage.hero.jpg" /> */}
        {/* <HeroText />
        <HeroImage image="homepage.hero.jpg" /> */}
        {/* <Pricing /> */}
        {/* <Header />
        <Hero />
        <Explanation />
        <HabitsList />
        <HabitsTypes />
        <JournalBox />
       */}
        <Footer />
      </div>
    </div>
  );
};
