import React from "react";
import css from "./style.module.scss";
import { Title, SectionTitle } from "components/ui/Typography";
import Background from "components/ui/Background";
import Button from "components/ui/Button";
import cn from "classnames";
import { Container, Content, Divider, Image } from "components/ui/Components";

const FooterTitle = ({ children }) => (
  <div className={css.title}>{children}</div>
);

const Column = ({ children, title }) => (
  <div className={css.column}>
    <FooterTitle>{title}</FooterTitle>
    {children}
  </div>
);

export default ({ children }) => {
  const year = 2020;
  return (
    <Container className={css.footer} color="current" noMargin>
      <Content className={css.footerContent}>
        <Column title={`${year} Tinysteps`}>
          <Button className={css.link} link linkStyle="none" to="/features">
            Features
          </Button>
          <Button className={css.link} link linkStyle="none" to="/extensions">
            Web Extensions
          </Button>
        </Column>
        <Column title="About">
          <Button className={css.link} link linkStyle="none" to="/about">
            About us
          </Button>
          <Button className={css.link} link linkStyle="none" to="/privacy">
            Privacy policy
          </Button>
        </Column>

        <Column title="Contact us">
          <Button
            className={css.link}
            link
            external
            linkStyle="none"
            to="mailto:hey@tinysteps.xyz"
          >
            Email
          </Button>
          <Button
            className={css.link}
            link
            external
            linkStyle="none"
            to="https://telegram.me/sono_franco"
          >
            Telegram
          </Button>
          <Button
            className={css.link}
            link
            external
            linkStyle="none"
            to="https://twitter.com/urcoilbisurco"
          >
            Twitter
          </Button>
        </Column>
      </Content>
    </Container>
  );
};
