import React from "react";
import css from "./style.module.scss";
import { Title, SectionTitle, Text, Mark } from "components/ui/Typography";
import Background from "components/ui/Background";
import Button from "components/ui/Button";
import cn from "classnames";
import Animator from "components/ui/Animator";
import { Container, Content, Section, Divider } from "components/ui/Components";

export default ({ children }) => {
  return (
    <Container className={css.hero}>
      <Content>
        <div>
          <Title>
            This is <Mark>TinySteps</Mark>,<br />a place where you can build
            habits, set new goals, and have fun journaling.
          </Title>
          <SectionTitle>
            Keep track of your everyday progress with TinySteps. <br />
            We know that habit formation is a long way, but while waiting for
            the rewards, we are here to <Mark>keep you motivated</Mark> and
            celebrate your improvements!
          </SectionTitle>
          <div>
            <Button big to="https://app.tinysteps.xyz/register">
              Try it free for 14 days
            </Button>
            <Button link className={css.link}>
              <Mark>No credit card required</Mark>
            </Button>
          </div>
        </div>
      </Content>
      <Divider color="color" />
    </Container>
  );
};
