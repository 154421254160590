import React from "react";
import css from "./style.module.scss";
import { Title, SectionTitle } from "components/ui/Typography";
import Background from "components/ui/Background";
import Button from "components/ui/Button";
import cn from "classnames";
import Explanation from "./Explanation";
import Extension from "./Extension";
import Footer from "components/ui/Footer";
import Pricing from "./Pricing";
import HeroText from "./HeroText";
import HeroImage from "./HeroImage";
import HeroHeader from "./HeroHeader";

export default ({ children }) => {
  return (
    <div>
      <div className={css.homepage}>
        <HeroHeader />
        <HeroText />
        <HeroImage image="homepage.hero.jpg" />
        <Explanation />
        <HeroImage image="habit.details.jpg" />
        <Extension />

        <Pricing />
        <Footer />
      </div>
    </div>
  );
};
