import React from "react";
import css from "./style.module.scss";
import { Title, SectionTitle, Text, Mark } from "components/ui/Typography";
import Background from "components/ui/Background";
import Button from "components/ui/Button";
import cn from "classnames";
import Animator from "components/ui/Animator";
import { Container, Content, Section, Divider } from "components/ui/Components";
import { HomepageDownload } from "components/Extensions/Download";
export default ({ children }) => {
  return (
    <Container className={css.hero}>
      <Content>
        <div>
          <Title>Use it everywhere</Title>
          <SectionTitle>
            <b>TinySteps</b> works perfectly on all devices.
            <br />
            You can also use our <Mark>TinySteps Browser extensions</Mark> (for
            Chrome, Firefox, Brave, and Edge), to load it as your new tab page
            and track your progress easier!
          </SectionTitle>
          <HomepageDownload />
        </div>
      </Content>
      <Divider color="color" />
    </Container>
  );
};
