import React from "react";
import cn from "classnames";
import css from "./style.module.scss";

export const Title = ({ children, className, ...rest }) => (
  <h1 {...rest} className={cn(css.title, className)}>
    {children}
  </h1>
);
export const Mark = ({ children, className, ...rest }) => (
  <span {...rest} className={cn(css.mark, className)}>
    <b>{children}</b>
  </span>
);
export const SubTitle = ({ children, className, ...rest }) => (
  <h3 {...rest} className={cn(css.subTitle, className)}>
    {children}
  </h3>
);
export const SectionTitle = ({ children, className, ...rest }) => (
  <h2 {...rest} className={cn(css.sectionTitle, className)}>
    {children}
  </h2>
);
export const BoxTitle = ({ children, className, ...rest }) => (
  <h3 {...rest} className={cn(css.boxTitle, className)}>
    {children}
  </h3>
);

export const Text = ({ children, onBackground, className, ...rest }) => (
  <p
    {...rest}
    className={cn(css.text, onBackground && css.textOnBackground, className)}
  >
    {children}
  </p>
);

export const SmallText = ({ children, className, ...rest }) => (
  <p {...rest} className={cn(css.smallText, className)}>
    {children}
  </p>
);

export default {};
