import React, { useEffect, useState } from "react";
import { Router, route } from "preact-router";
import Redirect from "components/ui/Redirect";
import Page from "components/ui/Page";
import Home from "components/Home";
import About from "components/About";
import Extensions from "components/Extensions";
import Privacy from "components/Privacy";

const TinyRouter = () => {
  // if (
  //   user.authenticated &&
  //   user.collabAppsCount === 0 &&
  //   user.subscription &&
  //   user.subscription.status < 1
  // ) {
  //   return <Subscribe />;
  // }
  return (
    <Page default>
      <Router
        onChange={() => {
          if (typeof window !== "undefined") {
            window.scrollTo(0, 0);
          }
        }}
      >
        <About path="/about" />
        <Extensions path="/extensions" />

        <Privacy path="/privacy" />
        <Home path="/:all?" />
      </Router>
    </Page>
  );
};

export default TinyRouter;
