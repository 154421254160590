import React from "react";
import css from "./style.module.scss";
import { Title, SectionTitle, SubTitle, Mark } from "components/ui/Typography";
import Background from "components/ui/Background";
import Button from "components/ui/Button";
import cn from "classnames";
import { Container, Content, Section, Divider } from "components/ui/Components";

const Profile = ({ name, description, link, image }) => (
  <div className={css.profile}>
    <img src={"/assets/" + image} className={css.image} />
    <div className={css.name}>{name}</div>
    <div className={css.description}>{description}</div>
    <Button link to={link} arrow className={css.link}>
      {link.replace("https://", "")}
    </Button>
  </div>
);

export default ({ children }) => {
  return (
    <div className={css.hero} id="pricing">
      <Content>
        <div>
          <Title>Come and meet us</Title>
          <SubTitle>
            And also, please say hi! We love to make new friends.
          </SubTitle>

          <div className={css.profiles}>
            <Profile
              name="Francesco"
              image="francesco.jpg"
              description="Francesco is the main developer and designer behind TinySteps.
He is best known for his great mustache and his love for tea 🍵"
              link="https://francesco.space"
            />
            <Profile
              name="Virginia"
              image="virginiapug.jpg"
              description="Virginia is TinySteps’ social media person/butterfly. If you see a great copy on the website or on our social profiles, 150% chances it was made by her. She is always ready to go out and skate 🛹"
              link="https://instagram.com/virginiapug"
            />
          </div>
          {/* <SectionTitle>
            <Mark>Why TinySteps isn't free?</Mark>
            <br />
            Charging for a subscription is the only way{" "}
            <a href="/about-us">we</a> make money. We do this so that we never
            have to show you a single ad or sell your data to any third-parties–
            and we promise we never will!
          </SectionTitle> */}
        </div>
      </Content>
      <Divider color="color" />
    </div>
  );
};
